// src/pages/projects/Project1.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Project1 = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Project 1: HTML Example
      </Typography>
      <div>
        <h1>Hello, World!</h1>
        <p>This is a simple HTML example.</p>
      </div>
    </Box>
  );
};

export default Project1;