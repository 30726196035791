// src/pages/Projects.js
import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Projects = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Projects
      </Typography>
      <List>
        <ListItem button component={Link} to="/projects/project1">
          <ListItemText primary="Project 1: HTML Example" />
        </ListItem>
        <ListItem button component={Link} to="/projects/project2">
          <ListItemText primary="Project 2: CSS Example" />
        </ListItem>
        <ListItem button component={Link} to="/projects/project3">
          <ListItemText primary="Project 3: JavaScript Example" />
        </ListItem>
      </List>
    </Box>
  );
};

export default Projects;