// src/pages/projects/Project3.js
import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

const Project3 = () => {
  const [count, setCount] = useState(0);

  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Project 3: JavaScript Example
      </Typography>
      <div>
        <p>Button clicked {count} times</p>
        <Button variant="contained" color="primary" onClick={handleClick}>
          Click me
        </Button>
      </div>
    </Box>
  );
};

export default Project3;