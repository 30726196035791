// src/pages/BlogList.js
import React from 'react';
import { Box, Grid2, Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';

const blogPosts = [
  { id: 'post1', title: 'Intro to web dev', excerpt: 'Dive Into Web Development and Start Building Your Future', image: '/SiteLogo.png' },
  { id: 'post2', title: 'Intro to HTML', excerpt: 'A brief overview of HTML.', image: '/SiteLogo.png' },
  { id: 'post3', title: 'Intro to HTML & CSS', excerpt: 'An introduction to styling elements.', image: '/SiteLogo.png' },
  { id: 'post4', title: 'Intro to Databases', excerpt: 'Databases are the backbone of web apps.', image: '/SiteLogo.png' },
];

const BlogList = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Blog Posts
      </Typography>
      <Grid2 container spacing={4}>
        {blogPosts.map((post) => (
          <Grid2 item key={post.id} xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea component={Link} to={`/blog/${post.id}`}>
                <CardMedia
                  component="img"
                  image={post.image}
                  alt={post.title}
                  sx={{
                    width: '64px',
                    height: 'auto',
                    margin: '0 auto', // Center the image horizontally
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {post.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      width: '180px', // Match the width of the image
                      wordWrap: 'break-word', // Ensure text wraps within the width
                      //margin: '0 auto', // Center the text horizontally
                    }}
                  >
                    {post.excerpt}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default BlogList;