// src/pages/Credentials.js
import React from 'react';
import { Grid, Card, CardActionArea, CardMedia } from '@mui/material';

const credentials = [
  { src: 'python-programming.1.png', alt: 'Python Programming', url: 'https://www.credly.com/badges/57df0102-9133-41ac-9393-f80938486ea1/public_url' },
  { src: 'project-management-professional-pmp.png', alt: 'Project Management Professional (PMP)', url: 'https://www.credly.com/badges/7b2223bd-3b0a-4cd5-8b87-d85c20ff23d0/public_url' },
  { src: 'modern-javascript-from-the-beginning.png', alt: 'Modern JavaScript from the Beginning', url: 'https://www.credly.com/badges/1b98d06f-d573-4e23-b473-6a8f527e107c/public_url' },
  { src: 'machine-learning.png', alt: 'Machine Learning', url: 'https://www.credly.com/badges/1ec06dfb-56ad-4b22-abf3-b2c423005a68/public_url' },
  { src: 'javascript-fundamentals.png', alt: 'JavaScript Fundamentals', url: 'https://www.credly.com/badges/c465fcec-02aa-4340-b5fb-e8ce2af41a3d/public_url' },
  { src: 'aws-cloud-practitioner-course.1.png', alt: 'AWS Cloud Practitioner Course', url: 'https://www.credly.com/badges/3fe6ce6e-5a6c-4d18-b117-ae57707fbb0a/public_url' },
];

const Credentials = () => {
  return (
    <Grid container spacing={2}>
      {credentials.map((credential, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardActionArea href={credential.url} target="_blank">
              <CardMedia
                component="img"
                image={process.env.PUBLIC_URL + '/' + credential.src}
                alt={credential.alt}
                sx={{ objectFit: 'contain', height: '100%', width: '100%' }}
              />
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Credentials;