// src/pages/Home.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import '../App.css';

const Home = () => {
  return (
    <Box className="home-container">
      {/* First Section */}
      <Box className="top-image">
        <Box className="image-cover">
          <Box sx={{ paddingLeft: '30vw', textAlign: 'left' }}>
            <Typography variant="h1" className="white-text">
              Crafting digital experiences
            </Typography>
            <Typography variant="h2" className="white-text">
              Elevate your projects with innovative web solutions
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Second Section */}
      <Box className="second-section">
        <Box className="text-content">
          <Typography variant="h2" className="green-text">
            CRAFTING DIGITAL EXPERIENCES
          </Typography>
          <Typography variant="body1">
            Jarred Deems is a dedicated web developer specializing in innovative JavaScript and CSS projects. With a strong command of the React framework, Jarred transforms ideas into dynamic web applications that captivate users. His portfolio showcases a variety of web development projects, a personal blog filled with insights, and professional credentials that highlight his expertise. Experience the fusion of vibrant Bauhaus-inspired design and modern functionality, all crafted to elevate your digital presence.
          </Typography>
        </Box>
        <Box
          component="img"
          sx={{
            height: 'auto',
            width: '100%',
            maxWidth: '600px', // Set a max-width to ensure it doesn't exceed the container width
          }}
          alt="A computer on a desk."
          src="../../webdev.jpeg"
        />
      </Box>

      {/* Third Section */}
      <Box className="third-section">
        <Typography variant="h2" className="green-text">
          DYNAMIC WEB SOLUTIONS
        </Typography>
        <Typography variant="h1" className="black-text">
          Crafting engaging websites with React & CSS
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;