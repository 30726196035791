// src/pages/BlogPost.js
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useParams } from 'react-router-dom';
import post1Content from './blog/post1.md';
import post2Content from './blog/post2.md';
import post3Content from './blog/post3.md';
import post4Content from './blog/post4.md';

const blogPosts = {
  post1: post1Content,
  post2: post2Content,
  post3: post3Content,
  post4: post4Content,
};

const BlogPost = () => {
  const { postId } = useParams();
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      const response = await fetch(blogPosts[postId]);
      const text = await response.text();
      setContent(text);
    };

    fetchContent();
  }, [postId]);

  if (!content) {
    return <Typography variant="h6">Post not found</Typography>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      {/* <Typography variant="h4" gutterBottom>
        {postId.replace(/post(\d+)/, 'Post $1')}
      </Typography> */}
      <ReactMarkdown
        children={content}
        rehypePlugins={[rehypeRaw]} // Add rehypeRaw to handle raw HTML
      />
    </Box>
  );
};

export default BlogPost;