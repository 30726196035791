// src/pages/projects/Project2.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const Project2 = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Project 2: CSS Example
      </Typography>
      <div style={{ border: '2px solid #4CAF50', padding: '16px', backgroundColor: '#f9f9f9' }}>
        <p style={{ color: '#4CAF50', fontWeight: 'bold' }}>This is a styled box using CSS.</p>
      </div>
    </Box>
  );
};

export default Project2;